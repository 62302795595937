import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Menu,
  MenuItem
} from '@mui/material';
import moment from 'moment';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ApiService } from 'src/services/ApiService';
import SuccessMessage from 'src/components/PopupMessage/SuccessMessage';
import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';
import SalesHistoryReceipt from '../../SalesHistory/SalesHistoryReceipt';
import ReactDOMServer from 'react-dom/server';
import { getLocalData } from 'src/utils';

export const PurchaseHistoryTable = (props) => {
  const { tableRows, searchQuery, openDetail } = props;
  const locationId = getLocalData('locationId');
  let BASE_URL = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}`;

  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [getRowItem, setGetRowItem] = useState({});

  const [anchor, setAnchor] = useState(null);

  const [showSucessMessage, setShowSuccessMessage] = useState(false);
  const [sucessMessage, setSuccessMessage] = useState('');

  const [showMessage, setShowMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const searchText = searchQuery.toLowerCase();
    setSearchText(searchText);
    setPage(0);
  }, [searchQuery]);

  const filteredData = tableRows.filter((item) => {
    return Object.values(item).some((value) =>
      value?.toString()?.toLowerCase()?.includes(searchText)
    );
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page when rows per page changes
  };

  const paginatedData = filteredData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  useEffect(() => {
    setPage(0);
  }, [tableRows]);

  const dateFormate = (date) => {
    let utc;
    utc = date ? moment?.utc(date).format('MMM DD, YYYY') : '';
    return utc;
  };

  const printReceipt = () => {
    const receiptHtml = ReactDOMServer.renderToStaticMarkup(
      <SalesHistoryReceipt selected={getRowItem} />
    );

    const printWindow = window.open('', '', 'width=600,height=600');

    if (printWindow) {
      // Ensure the new window is opened only once
      printWindow.document.open();
      printWindow.document.write(`
        <html>
          <head>
            <title>Receipt</title>
          </head>
          <body>
            ${receiptHtml}
          </body>
        </html>
      `);
      printWindow.document.close();

      printWindow.onload = () => {
        printWindow.print();
        printWindow.onafterprint = () => {
          printWindow.close();
        };
      };
    } else {
      alert('Pop-up window blocked. Please allow pop-ups for this site.');
    }
  };

  const handleEmailReceipt = async () => {
    const ZROrderIdsss = encodeURIComponent(getRowItem?.sortKey);
    let encodedLocationId = encodeURIComponent(locationId);

    try {
      let response = await ApiService.get(
        `${BASE_URL}checkout/orderEmailReceipt?locationId=${encodedLocationId}&orderId=${ZROrderIdsss}`
      );

      if (response?.statusCode === 200) {
        setShowSuccessMessage(true);
        setSuccessMessage(
          response?.data?.message || 'Email has been sent successfully'
        );
      }
    } catch (error) {
      console.error(error);
      setShowMessage(true);
      setErrorMessage(error?.message || 'Something went Wrong');
    }
  };

  const openMenu = (rowDetails, event) => {
    event.stopPropagation();
    setGetRowItem(rowDetails);
    setAnchor(event.currentTarget);
  };

  const closeMenu = (e) => {
    e.stopPropagation();
    setGetRowItem({});
    setAnchor(null);
  };

  return (
    <div>
      {showMessage && (
        <ErrorMessage message={errorMessage} setShowMessage={setShowMessage} />
      )}
      {showSucessMessage && (
        <SuccessMessage
          message={sucessMessage}
          setShowMessage={setSuccessMessage}
          closePopup={() => setShowSuccessMessage(false)}
        />
      )}
      <Card
        sx={{
          padding: '15px',
          borderRadius: '19px 19px 0px 0px'
        }}
      >
        <TableContainer className="location-table-container">
          <Table>
            <TableHead>
              <TableRow className="table-header">
                <TableCell>Date</TableCell>
                <TableCell>Payment Status</TableCell>
                <TableCell>Payment Method</TableCell>
                <TableCell>Refund Status</TableCell>
                <TableCell>Refund Amount</TableCell>
                <TableCell>Card Number</TableCell>
                <TableCell>Dog</TableCell>
                <TableCell>Discount</TableCell>
                <TableCell>Total Price</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              {(paginatedData &&
                paginatedData.length > 0 &&
                paginatedData.map((row) => {
                  return (
                    <TableRow
                      hover
                      key={row.sortKey}
                      role="checkbox"
                      tabIndex={-1}
                      className="table-row cursor-pointer"
                      onClick={(e) => openDetail(row.sortKey, 3, e)}
                    >
                      <TableCell className="table-td">
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          // sx={{ marginTop: 1 }}
                          gutterBottom
                          noWrap
                        >
                          {row?.orderDate
                            ? dateFormate(row.orderDate)
                            : row?.dateOfPurchase &&
                              dateFormate(row.dateOfPurchase)}
                        </Typography>
                      </TableCell>
                      <TableCell className="table-td">
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          {row?.sqaureOrderState === 'OPEN'
                            ? 'UNPAID'
                            : row?.sqaureOrderState}
                        </Typography>
                      </TableCell>
                      <TableCell className="table-td">
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          {row?.paymentSourceType}
                        </Typography>
                      </TableCell>
                      <TableCell className="table-td">
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          {row?.refundStatus ? row?.refundStatus : '-'}
                        </Typography>
                      </TableCell>
                      <TableCell className="table-td">
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          {row?.refundAmount
                            ? `$${parseFloat(row?.refundAmount)?.toFixed(2)}`
                            : '-'}
                        </Typography>
                      </TableCell>
                      <TableCell className="table-td">
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          {row?.cardNumber ? row?.cardNumber : '-'}
                        </Typography>
                      </TableCell>
                      <TableCell className="table-td">
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          {row?.petName}
                        </Typography>
                      </TableCell>
                      <TableCell className="table-td">
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          $
                          {parseFloat(
                            row?.totalSquareDiscountAmountInDollar
                          )?.toFixed(2) ??
                            parseFloat(row?.totalDiscount)?.toFixed(2) ??
                            '0.00'}
                        </Typography>
                      </TableCell>
                      <TableCell className="table-td">
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          $
                          {parseFloat(
                            row?.totalSquareAmountWithTaxInDollar
                          )?.toFixed(2) ?? '0.00'}
                        </Typography>
                      </TableCell>

                      <TableCell className="table-td">
                        <MoreVertIcon
                          onClick={(e) => openMenu(row, e)}
                          color="grey"
                          variant="contained"
                          className="three-dots-icon"
                        />

                        <Menu
                          open={Boolean(anchor)}
                          anchorEl={anchor}
                          onClose={(e) => closeMenu(e)}
                          keepMounted
                          PaperProps={{
                            style: {
                              maxHeight: 40 * 5,
                              width: '25ch'
                            }
                          }}
                        >
                          <MenuItem
                            onClick={(e) => {
                              e.stopPropagation();
                              handleEmailReceipt();
                            }}
                          >
                            Email Receipt
                          </MenuItem>
                          <MenuItem
                            onClick={(e) => {
                              e.stopPropagation();
                              printReceipt();
                            }}
                          >
                            Print Receipt
                          </MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  );
                })) || (
                <TableRow>
                  <TableCell colSpan={6} className="table-td">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      sx={{ marginTop: 1 }}
                      gutterBottom
                      noWrap
                    >
                      No data found
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Box p={2}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Card>
    </div>
  );
};
